import { inject } from '@angular/core';
import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AuthGuard } from '@core/guards';
import { environment } from 'src/environments/environment';

export const routes: Routes = [
    { 
        path: '', redirectTo: 'auth/login', pathMatch: 'full',
    },
    {
        path: 'auth',
        loadChildren: () => import('src/app/modules/auth/auth.module').then(m => m.AuthModule),
    },
    {
        path: 'approx',
        loadChildren: () => import('src/app/layouts/approx/layout.module').then(m => m.ApproxLayoutModule),
        canActivate: [MsalGuard],
    },
    {
        path: 'private',
        loadChildren: () => import('src/app/layouts/main/layout.module').then(m => m.MainLayoutModule),
        canActivate: [() => inject(AuthGuard).canActivate()],
    },
    {
        path: '**',
        loadComponent: () => import('src/app/modules/error/error404/error404').then(c => c.Error404Component)
    }
];
