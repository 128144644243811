/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { approveArticleTranslation } from '../fn/translations/approve-article-translation';
import { ApproveArticleTranslation$Params } from '../fn/translations/approve-article-translation';
import { ChangeLogResponse } from '../models/change-log-response';
import { disapproveArticleTranslation } from '../fn/translations/disapprove-article-translation';
import { DisapproveArticleTranslation$Params } from '../fn/translations/disapprove-article-translation';
import { getArticleTranslation } from '../fn/translations/get-article-translation';
import { GetArticleTranslation$Params } from '../fn/translations/get-article-translation';
import { getParagraphTranslationHistory } from '../fn/translations/get-paragraph-translation-history';
import { GetParagraphTranslationHistory$Params } from '../fn/translations/get-paragraph-translation-history';
import { ResourceResponse } from '../models/resource-response';
import { TranslationContentResponse } from '../models/translation-content-response';
import { updateParagraphTranslation } from '../fn/translations/update-paragraph-translation';
import { UpdateParagraphTranslation$Params } from '../fn/translations/update-paragraph-translation';


/**
 * Actions related to EU Laws Translations under a certain project
 */
@Injectable({ providedIn: 'root' })
export class TranslationsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getArticleTranslation()` */
  static readonly GetArticleTranslationPath = '/projects/{projectId}/euLaws/{lawId}/translations/{articleId}';

  /**
   * Get Translations of an Article in a Project.
   *
   * Retrieve the translation of a specific article in an EU Law associated with a specific Project (Transposition)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArticleTranslation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArticleTranslation$Response(params: GetArticleTranslation$Params, context?: HttpContext): Observable<StrictHttpResponse<TranslationContentResponse>> {
    return getArticleTranslation(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Translations of an Article in a Project.
   *
   * Retrieve the translation of a specific article in an EU Law associated with a specific Project (Transposition)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getArticleTranslation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArticleTranslation(params: GetArticleTranslation$Params, context?: HttpContext): Observable<TranslationContentResponse> {
    return this.getArticleTranslation$Response(params, context).pipe(
      map((r: StrictHttpResponse<TranslationContentResponse>): TranslationContentResponse => r.body)
    );
  }

  /** Path part for operation `getParagraphTranslationHistory()` */
  static readonly GetParagraphTranslationHistoryPath = '/projects/{projectId}/euLaws/{lawId}/translations/{articleId}/paragraphs/{paragraphId}/history';

  /**
   * Get Translation History of a Paragraph in an Article.
   *
   * Retrieve the history of changes for a specific paragraph in an article of an EU Law associated with a specific Project (Transposition)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getParagraphTranslationHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getParagraphTranslationHistory$Response(params: GetParagraphTranslationHistory$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ChangeLogResponse>>> {
    return getParagraphTranslationHistory(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Translation History of a Paragraph in an Article.
   *
   * Retrieve the history of changes for a specific paragraph in an article of an EU Law associated with a specific Project (Transposition)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getParagraphTranslationHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getParagraphTranslationHistory(params: GetParagraphTranslationHistory$Params, context?: HttpContext): Observable<Array<ChangeLogResponse>> {
    return this.getParagraphTranslationHistory$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ChangeLogResponse>>): Array<ChangeLogResponse> => r.body)
    );
  }

  /** Path part for operation `updateParagraphTranslation()` */
  static readonly UpdateParagraphTranslationPath = '/projects/{projectId}/euLaws/{lawId}/translations/{articleId}/paragraphs/{paragraphId}';

  /**
   * Update Translation of a Paragraph in an Article.
   *
   * Update the translation of a specific paragraph in an article of an EU Law associated with a specific Project (Transposition)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateParagraphTranslation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateParagraphTranslation$Response(params: UpdateParagraphTranslation$Params, context?: HttpContext): Observable<StrictHttpResponse<ResourceResponse>> {
    return updateParagraphTranslation(this.http, this.rootUrl, params, context);
  }

  /**
   * Update Translation of a Paragraph in an Article.
   *
   * Update the translation of a specific paragraph in an article of an EU Law associated with a specific Project (Transposition)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateParagraphTranslation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateParagraphTranslation(params: UpdateParagraphTranslation$Params, context?: HttpContext): Observable<ResourceResponse> {
    return this.updateParagraphTranslation$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResourceResponse>): ResourceResponse => r.body)
    );
  }

  /** Path part for operation `approveArticleTranslation()` */
  static readonly ApproveArticleTranslationPath = '/projects/{projectId}/euLaws/{lawId}/translations/{articleId}/approve';

  /**
   * Approve Translation of Paragraphs in an Article.
   *
   * **Approve** the translation of selected paragraphs in an article within an EU Law associated with a specific Project (Transposition). The authenticated user with the right permissions can **disapprove** the translation if it was **previously approved**.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `approveArticleTranslation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  approveArticleTranslation$Response(params: ApproveArticleTranslation$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'results'?: ResourceResponse;
}>> {
    return approveArticleTranslation(this.http, this.rootUrl, params, context);
  }

  /**
   * Approve Translation of Paragraphs in an Article.
   *
   * **Approve** the translation of selected paragraphs in an article within an EU Law associated with a specific Project (Transposition). The authenticated user with the right permissions can **disapprove** the translation if it was **previously approved**.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `approveArticleTranslation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  approveArticleTranslation(params: ApproveArticleTranslation$Params, context?: HttpContext): Observable<{
'results'?: ResourceResponse;
}> {
    return this.approveArticleTranslation$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'results'?: ResourceResponse;
}>): {
'results'?: ResourceResponse;
} => r.body)
    );
  }

  /** Path part for operation `disapproveArticleTranslation()` */
  static readonly DisapproveArticleTranslationPath = '/projects/{projectId}/euLaws/{lawId}/translations/{articleId}/paragraphs/{paragraphId}/disapprove';

  /**
   * Disapprove Translation of a Paragraph in an Article.
   *
   * **Disapprove** the translation of a paragraph in an article within an EU Law associated with a specific Project (Transposition).
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `disapproveArticleTranslation()` instead.
   *
   * This method doesn't expect any request body.
   */
  disapproveArticleTranslation$Response(params: DisapproveArticleTranslation$Params, context?: HttpContext): Observable<StrictHttpResponse<ResourceResponse>> {
    return disapproveArticleTranslation(this.http, this.rootUrl, params, context);
  }

  /**
   * Disapprove Translation of a Paragraph in an Article.
   *
   * **Disapprove** the translation of a paragraph in an article within an EU Law associated with a specific Project (Transposition).
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `disapproveArticleTranslation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  disapproveArticleTranslation(params: DisapproveArticleTranslation$Params, context?: HttpContext): Observable<ResourceResponse> {
    return this.disapproveArticleTranslation$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResourceResponse>): ResourceResponse => r.body)
    );
  }

}
