import { AuthRolesEnum } from '@core/api';
import { UserAction } from '@core/common/user-actions';
import { AuthModules } from '@mis/auth';
import { FeatureIDs } from '@shared/routing/enums/features';

export const MODULE_ROLE_MAPPING: { [key in FeatureIDs]: Array<AuthRolesEnum> | undefined } = {
  [FeatureIDs.dashboard]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieMember],
  // Modulet e raportit te progresit
  [FeatureIDs.progressReportList]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieMember],
  [FeatureIDs.progressReportView]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieMember],
  [FeatureIDs.progressReportTask]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieMember],
  // Rekomandimet
  [FeatureIDs.recommendationsList]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.Negotiator, AuthRolesEnum.GnpieMember, AuthRolesEnum.ChiefGnpieSecretariat],
  [FeatureIDs.bookmarkList]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.Negotiator, AuthRolesEnum.GnpieMember, AuthRolesEnum.ChiefGnpieSecretariat],
  [FeatureIDs.bookmarkView]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.Negotiator, AuthRolesEnum.GnpieMember, AuthRolesEnum.ChiefGnpieSecretariat],
  [FeatureIDs.bookmarkTaskView]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.Negotiator, AuthRolesEnum.GnpieMember, AuthRolesEnum.ChiefGnpieSecretariat],
  // Banka e pyetjeve
  [FeatureIDs.bankOfQuestions]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.HorizontalCoordinator],
  // Pasaporta e kapitullit
  [FeatureIDs.chapterPassport]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.Negotiator],
  // Acquis
  [FeatureIDs.acquisList]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.GnpieMember],
  [FeatureIDs.acquisView]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.GnpieMember],
  [FeatureIDs.acquisTaskView]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.GnpieMember],
  // Work Plan
  [FeatureIDs.workPlanList]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.GnpieMember],
  [FeatureIDs.workPlanView]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.GnpieMember],
  [FeatureIDs.monitoring]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.GnpieMember],
  // Menaxhimi i akseseve
  [FeatureIDs.userManagement]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.UserManager],
  [FeatureIDs.institutionManagement]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.UserManager],
  [FeatureIDs.errorLogs]: [AuthRolesEnum.UserManager, AuthRolesEnum.PmoSecretariat],
  // Kalendari
  [FeatureIDs.calendar]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieMember],
  [FeatureIDs.calendarTask]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieMember],
  // Kapacitetet
  [FeatureIDs.expertsDb]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.Negotiator],
  [FeatureIDs.donatorsDb]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator],
  [FeatureIDs.taiexDb]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator],
  [FeatureIDs.projectsDb]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.Negotiator],
  [FeatureIDs.projectView]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.Negotiator],
  [FeatureIDs.priorityList]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieSecretariat],
  [FeatureIDs.twinningDb]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator],
  [FeatureIDs.trainingNeeds]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator],
  [FeatureIDs.taiexNeeds]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator],
  [FeatureIDs.twinningNeeds]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator],
  [FeatureIDs.gnpieTrainingNeed]: [AuthRolesEnum.Negotiator, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.GnpieMember],
  [FeatureIDs.donatorView]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator],
  [FeatureIDs.integration]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.Negotiator, AuthRolesEnum.GnpieMember, AuthRolesEnum.ChiefGnpieSecretariat],
  [FeatureIDs.integrationCase]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.Negotiator, AuthRolesEnum.GnpieMember, AuthRolesEnum.ChiefGnpieSecretariat],
  [FeatureIDs.integrationTask]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.Negotiator, AuthRolesEnum.GnpieMember, AuthRolesEnum.ChiefGnpieSecretariat],
  [FeatureIDs.notifications]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.Negotiator, AuthRolesEnum.GnpieMember, AuthRolesEnum.ChiefGnpieSecretariat],
  [FeatureIDs.documents]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.GnpieMember],
  [FeatureIDs.documentDetail]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.Negotiator],
  [FeatureIDs.supportFields]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator],
  [FeatureIDs.lga]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.Negotiator],
  [FeatureIDs.iaga]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.Negotiator],
  [FeatureIDs.roadmapList]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.Negotiator, AuthRolesEnum.GnpieMember, AuthRolesEnum.ChiefGnpieSecretariat],
  [FeatureIDs.roadmapTask]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.Negotiator, AuthRolesEnum.GnpieMember, AuthRolesEnum.ChiefGnpieSecretariat],
  [FeatureIDs.roadmapSubtask]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.Negotiator, AuthRolesEnum.GnpieMember, AuthRolesEnum.ChiefGnpieSecretariat],
  [FeatureIDs.albanianLaws]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.Negotiator, AuthRolesEnum.GnpieMember, AuthRolesEnum.ChiefGnpieSecretariat],
  [FeatureIDs.report]: [AuthRolesEnum.MainNegotiator, AuthRolesEnum.PmoSecretariat, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.Negotiator],
  [FeatureIDs.topicTree]: [AuthRolesEnum.MainNegotiator, AuthRolesEnum.PmoSecretariat, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.Negotiator],
  [FeatureIDs.nenkomitetet]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.Negotiator, AuthRolesEnum.GnpieMember, AuthRolesEnum.ChiefGnpieSecretariat], //Object.values(AuthRolesEnum),
  [FeatureIDs.userManual]: Object.values(AuthRolesEnum).filter(role => role !== AuthRolesEnum.UserManager),
  [FeatureIDs.integrationQuestions]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator],
  [FeatureIDs.roadmapPlan]: Object.values(AuthRolesEnum).filter(role => role !== AuthRolesEnum.UserManager),
};

export const ROLE_MODULE_ACTION_MAPPING: { [key in FeatureIDs]: { [key: string]: Array<AuthRolesEnum> } | undefined } = {
  [FeatureIDs.progressReportView]: {
    [UserAction.ProgresReportViewActions.ADD_PRIORITY]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.HorizontalCoordinator],
    [UserAction.ProgresReportViewActions.REMOVE_PRIORITY]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.HorizontalCoordinator],
    [UserAction.ProgresReportViewActions.GENERATE_REPORT]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.HorizontalCoordinator],
    [UserAction.ProgresReportViewActions.ASSIGN_REPORTER]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.HorizontalCoordinator],
    [UserAction.ProgresReportViewActions.ASSIGN_CHIEF_SECRETARIAT]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.HorizontalCoordinator],
    [UserAction.ProgresReportViewActions.CHANGE_STATUS]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.HorizontalCoordinator],
    [UserAction.ProgresReportViewActions.VIEW_CHAPTERS]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.HorizontalCoordinator, AuthRolesEnum.ClusterCoordinator],
    [UserAction.ProgresReportViewActions.VIEW_SIGNED_DOCUMENTS]: [AuthRolesEnum.Negotiator, AuthRolesEnum.HorizontalCoordinator],
    [UserAction.ProgresReportViewActions.SEND_FOR_SIGNATURE]: [AuthRolesEnum.Negotiator],
    [UserAction.ProgresReportViewActions.SIGN_CHAPTER]: [AuthRolesEnum.Negotiator],
    [UserAction.ProgresReportViewActions.GENERATE_DOCUMENT]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.HorizontalCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.MainNegotiator, AuthRolesEnum.Negotiator],
    [UserAction.ProgresReportViewActions.ACTION_BUTTON]: [AuthRolesEnum.PmoSecretariat],
  },
  [FeatureIDs.progressReportList]: {
    [UserAction.ProgresReportListActions.CREATE_REPORT]: [AuthRolesEnum.PmoSecretariat],
  },
  [FeatureIDs.bookmarkList]: {
    [UserAction.BookmarkListActions.CREATE_BOOKMARK]: [AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieSecretariat],
    [UserAction.BookmarkListActions.EDIT_BOOKMARK]: [AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieSecretariat],
    [UserAction.BookmarkListActions.DELETE_BOOKMARK]: [AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieSecretariat],
    [UserAction.BookmarkListActions.GENERATE_DOCUMENT]: [AuthRolesEnum.PmoSecretariat],
  },
  [FeatureIDs.bookmarkView]: {
    [UserAction.BookmarkViewActions.CREATE_TASK]: [AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.GnpieMember],
  },
  [FeatureIDs.bookmarkTaskView]: {
    [UserAction.BookmarkTaskActions.REMOVE_MEMBER]: [AuthRolesEnum.GnpieSecretariat],
  },
  [FeatureIDs.workPlanView]: {
    [UserAction.WorkPlanViewActions.ADD_ACQUIS]: [AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.GnpieMember],
    [UserAction.WorkPlanViewActions.DELETE_ACQUIS]: [AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.GnpieMember],
    [UserAction.WorkPlanViewActions.SIGN_DOCUMENT]: [AuthRolesEnum.Negotiator],
    [UserAction.WorkPlanViewActions.VIEW_SIGNED_DOCUMENTS]: [AuthRolesEnum.MainNegotiator, AuthRolesEnum.PmoSecretariat, AuthRolesEnum.Negotiator, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.HorizontalCoordinator],
    [UserAction.WorkPlanViewActions.ADD_ACQUIS]: [AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.GnpieMember],
    [UserAction.WorkPlanViewActions.EDIT_MEMBERS]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.Negotiator, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.HorizontalCoordinator, AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieSecretariat],
  },
  [FeatureIDs.monitoring]: {
    [UserAction.MonitoringAction.GENERATE_DOCUMENT]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.Negotiator],
  },
  [FeatureIDs.workPlanList]: {
    [UserAction.WorkPlanActions.CREATE_WORK_PLAN]: [AuthRolesEnum.PmoSecretariat],
    [UserAction.WorkPlanActions.DELETE_WORK_PLAN]: [AuthRolesEnum.PmoSecretariat],
    [UserAction.WorkPlanActions.UPDATE_WORK_PLAN]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.HorizontalCoordinator],
    [UserAction.WorkPlanActions.DOWNLOAD_WORK_PLAN]: [AuthRolesEnum.PmoSecretariat],
    [UserAction.WorkPlanActions.CHANGE_STATUS]: [AuthRolesEnum.PmoSecretariat],
    [UserAction.WorkPlanActions.GENERATE_DOCUMENT]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.Negotiator],
  },
  [FeatureIDs.chapterPassport]: {
    [UserAction.ChapterPassportActions.CHANGE_CHAPTER]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.HorizontalCoordinator],
    [UserAction.ChapterPassportActions.CHANGE_LEAD_INST]: [AuthRolesEnum.PmoSecretariat],
    [UserAction.ChapterPassportActions.CHANGE_SUBCHAPTER_LEAD]: [AuthRolesEnum.PmoSecretariat],
  },
  [FeatureIDs.acquisView]: {
    [UserAction.AcquisViewActions.CREATE_TASK]: [AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.GnpieMember],
    [UserAction.AcquisViewActions.CHANGE_STATUS]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.HorizontalCoordinator, AuthRolesEnum.Negotiator],
    [UserAction.AcquisViewActions.ADD_INSTITUTION]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.HorizontalCoordinator],
    [UserAction.AcquisViewActions.DELETE_NATIONAL_ACT]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.Negotiator, AuthRolesEnum.HorizontalCoordinator],
    [UserAction.AcquisViewActions.DELETE_INSTITUTION]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.HorizontalCoordinator],
    [UserAction.AcquisViewActions.ADD_ACT]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.HorizontalCoordinator],
    [UserAction.AcquisViewActions.ADD_IAGA]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.HorizontalCoordinator],
    [UserAction.AcquisViewActions.ADD_LGA]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.HorizontalCoordinator],
  },
  [FeatureIDs.acquisTaskView]: {
    [UserAction.AcquisTaskViewActions.CHANGE_STATUS]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.HorizontalCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.GnpieMember],
    [UserAction.AcquisTaskViewActions.UPDATE_LEVEL_ALIGNMENT]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.HorizontalCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieSecretariat],
    [UserAction.AcquisTaskViewActions.UPDATE_TYPE_ALIGNMENT]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.HorizontalCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieSecretariat],
    [UserAction.AcquisTaskViewActions.UPDATE_IMPACT_AREA]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.HorizontalCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieSecretariat],
    [UserAction.AcquisTaskViewActions.UPDATE_TEXT]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.HorizontalCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieSecretariat],
    [UserAction.AcquisTaskViewActions.ADD_BUDGET]: [AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.GnpieMember],
    [UserAction.AcquisTaskViewActions.DELETE_BUDGET]: [AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat],
    [UserAction.AcquisTaskViewActions.ADD_BOOKMARK]: [AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.GnpieMember],
    [UserAction.AcquisTaskViewActions.ADD_NATIONAL_ACT]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.HorizontalCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.GnpieMember],
    [UserAction.AcquisTaskViewActions.REMOVE_MEMBER]: [AuthRolesEnum.GnpieSecretariat],
  },
  [FeatureIDs.recommendationsList]: {
    [UserAction.RecommendationListActions.CREATE_RECOMMENDATION]: [AuthRolesEnum.PmoSecretariat]
  },
  [FeatureIDs.calendar]: {
    [UserAction.CalendarActions.CREATE_MEETING]: [AuthRolesEnum.MainNegotiator, AuthRolesEnum.PmoSecretariat, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.GnpieMember, AuthRolesEnum.HorizontalCoordinator],
    [UserAction.CalendarActions.ADD_TASK]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.HorizontalCoordinator],
    [UserAction.CalendarActions.ADD_COORIDNATORS_MEETING]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.HorizontalCoordinator],
    [UserAction.CalendarActions.ADD_NEGOTIATORS_MEETING]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.HorizontalCoordinator],
    [UserAction.CalendarActions.ADD_CHIEF_MEETING]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.Negotiator, AuthRolesEnum.HorizontalCoordinator],
    [UserAction.CalendarActions.ADD_SPECIALISTS_MEETING]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.HorizontalCoordinator],
    [UserAction.CalendarActions.ADD_MEMBERS_MEETING]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.HorizontalCoordinator],
  },
  [FeatureIDs.calendarTask]: undefined,
  [FeatureIDs.expertsDb]: {
    [UserAction.ExpertsActions.ADD_EXPERT]: [AuthRolesEnum.PmoSecretariat],
    [UserAction.ExpertsActions.UPDATE_EXPERT]: [AuthRolesEnum.PmoSecretariat],
    [UserAction.ExpertsActions.DELETE_EXPERT]: [AuthRolesEnum.PmoSecretariat],
    [UserAction.ExpertsActions.GENERATE_EXPERT]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.Negotiator],
  },
  [FeatureIDs.donatorsDb]: {
    [UserAction.DonatorsActions.ADD_DONATOR]: [AuthRolesEnum.PmoSecretariat],
    [UserAction.DonatorsActions.UPDATE_DONATOR]: [AuthRolesEnum.PmoSecretariat],
    [UserAction.DonatorsActions.DELETE_DONATOR]: [AuthRolesEnum.PmoSecretariat],
    [UserAction.DonatorsActions.GENERATE_REPORT]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.Negotiator],
  },
  [FeatureIDs.projectsDb]: {
    [UserAction.ProjectsActions.ADD_PROJECT]: [AuthRolesEnum.PmoSecretariat],
    [UserAction.ProjectsActions.UPDATE_PROJECT]: [AuthRolesEnum.PmoSecretariat],
    [UserAction.ProjectsActions.DELETE_PROJECT]: [AuthRolesEnum.PmoSecretariat],
    [UserAction.ProjectsActions.GENERATE_REPORT]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.Negotiator],
  },
  [FeatureIDs.projectView]: {
    [UserAction.ProjectViewActions.DELETE_PROJECT]: [AuthRolesEnum.PmoSecretariat],
  },
  [FeatureIDs.priorityList]: {
    [UserAction.PriorityListActions.ADD_PRIORITY_LIST]: [AuthRolesEnum.PmoSecretariat],
    [UserAction.PriorityListActions.UPDATE_PRIORITY_LIST]: [AuthRolesEnum.PmoSecretariat],
    [UserAction.PriorityListActions.DELETE_PRIORITY_LIST]: [AuthRolesEnum.PmoSecretariat],
    [UserAction.PriorityListActions.GENERATE_REPORT]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.Negotiator],
  },
  [FeatureIDs.trainingNeeds]: {
    [UserAction.TrainingNeedsActions.ADD_TRAINING_NEEDS]: [AuthRolesEnum.PmoSecretariat],
    [UserAction.TrainingNeedsActions.UPDATE_TRAINING_NEEDS]: [AuthRolesEnum.PmoSecretariat],
    [UserAction.TrainingNeedsActions.APPROVE_TRAINING_NEEDS]: [AuthRolesEnum.PmoSecretariat],
    [UserAction.TrainingNeedsActions.DISAPPROVE_TRAINING_NEEDS]: [AuthRolesEnum.PmoSecretariat],
    [UserAction.TrainingNeedsActions.ADD_TAIEX]: [AuthRolesEnum.PmoSecretariat],
    [UserAction.TrainingNeedsActions.ADD_TWINNING]: [AuthRolesEnum.PmoSecretariat],
  },
  [FeatureIDs.dashboard]: {
    [UserAction.DashboardActions.CHANGE_CHAPTER]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ClusterCoordinator],
    [UserAction.DashboardActions.SHOW_WORK_PLAN_TASK_CHART]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat],
  },
  [FeatureIDs.progressReportTask]: undefined,
  [FeatureIDs.bankOfQuestions]: {
    [UserAction.BankOfQuestionsActions.CREATE_QUESTION]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.HorizontalCoordinator],
    [UserAction.BankOfQuestionsActions.EDIT_QUESTION]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.HorizontalCoordinator],
  },
  [FeatureIDs.integrationQuestions]: {
    [UserAction.BankOfQuestionsActions.CREATE_QUESTION]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.HorizontalCoordinator],
    [UserAction.BankOfQuestionsActions.EDIT_QUESTION]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.HorizontalCoordinator],
  },
  [FeatureIDs.acquisList]: {
    [UserAction.AcquisActions.ADD_ACQUIS]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieSecretariat],
    [UserAction.AcquisActions.EDIT_ACQUIS]: [AuthRolesEnum.PmoSecretariat],
    [UserAction.AcquisActions.ALERT_VIEW]: [AuthRolesEnum.Negotiator],
    [UserAction.AcquisActions.VIEW_LOGGED_CHAPTER]: [AuthRolesEnum.Negotiator, AuthRolesEnum.ChapterCommunication, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ChapterMonitoring, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieMember, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.Member],
  },
  [FeatureIDs.userManagement]: {
    [UserAction.UserManagementActions.CREATE_USER]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.UserManager],
    [UserAction.UserManagementActions.UPDATE_USER]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.UserManager],
  },
  [FeatureIDs.institutionManagement]: {
    [UserAction.InstitutionsActions.CREATE_INSTITUTION]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.UserManager],
    [UserAction.InstitutionsActions.DELETE_INSTITUTION]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.UserManager]
  },
  [FeatureIDs.taiexDb]: {
    [UserAction.TaiexActions.ADD_TAIEX]: [AuthRolesEnum.PmoSecretariat],
    [UserAction.TaiexActions.UPDATE_TAIEX]: [AuthRolesEnum.PmoSecretariat],
    [UserAction.TaiexActions.DELETE_TAIEX]: [AuthRolesEnum.PmoSecretariat],
  },
  [FeatureIDs.twinningDb]: {
    [UserAction.TwinningActions.ADD_TWINNING]: [AuthRolesEnum.PmoSecretariat],
    [UserAction.TwinningActions.UPDATE_TWINNING]: [AuthRolesEnum.PmoSecretariat],
    [UserAction.TwinningActions.DELETE_TWINNING]: [AuthRolesEnum.PmoSecretariat],
  },
  [FeatureIDs.taiexNeeds]: {
    [UserAction.TaiexNeedsActions.REMOVE_TRAINING_NEED]: [AuthRolesEnum.PmoSecretariat],
  },
  [FeatureIDs.twinningNeeds]: {
    [UserAction.TwinningNeedsActions.REMOVE_TRAINING_NEED]: [AuthRolesEnum.PmoSecretariat],
  },
  [FeatureIDs.gnpieTrainingNeed]: {
    [UserAction.GnpieTrainingNeedActions.ADD_TRAINING_NEED]: [AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.GnpieMember],
    [UserAction.GnpieTrainingNeedActions.UPDATE_TRAINING_NEED]: [AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.GnpieMember],
  },
  [FeatureIDs.donatorView]: undefined,
  [FeatureIDs.notifications]: undefined,

  [FeatureIDs.integration]: {
    [UserAction.IntegrationActions.CHANGE_STATUS]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.HorizontalCoordinator, AuthRolesEnum.ClusterCoordinator],
    [UserAction.IntegrationActions.CHANGE_CHAPTER]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.HorizontalCoordinator],
    [UserAction.IntegrationActions.SIGN_CHAPTER]: [AuthRolesEnum.Negotiator],
    [UserAction.IntegrationActions.CREATE_DOCUMENT]: [AuthRolesEnum.PmoSecretariat],
    [UserAction.IntegrationActions.GENERATE_DOCUMENT]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.Negotiator],
    [UserAction.IntegrationActions.CHANGE_NEGOTIATOR]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.HorizontalCoordinator],
    [UserAction.IntegrationActions.CHANGE_CHIEF]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.Negotiator, AuthRolesEnum.HorizontalCoordinator],
    [UserAction.IntegrationActions.VIEW_SIGNED_DOCUMENTS]: [AuthRolesEnum.Negotiator, AuthRolesEnum.PmoSecretariat, AuthRolesEnum.HorizontalCoordinator],
  },
  [FeatureIDs.documents]: undefined,
  [FeatureIDs.documentDetail]: undefined,
  [FeatureIDs.integrationCase]: {
    [UserAction.IntegrationCaseActions.CREATE_TASK]: [AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.GnpieMember],
  },
  [FeatureIDs.integrationTask]: {
    [UserAction.IntegrationTaskActions.REMOVE_MEMBER]: [AuthRolesEnum.GnpieSecretariat],
  },
  [FeatureIDs.supportFields]: {
    [UserAction.SupportFieldsActions.ADD_CATEGORY]: [AuthRolesEnum.PmoSecretariat],
    [UserAction.SupportFieldsActions.ADD_FIELD]: [AuthRolesEnum.PmoSecretariat],
  },
  [FeatureIDs.lga]: {
    [UserAction.LGAViewActions.GENERATE_DOCUMENT]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.Negotiator],
  },
  [FeatureIDs.iaga]: {
    [UserAction.IAGAViewActions.GENERATE_DOCUMENT]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.Negotiator],
  },
  [FeatureIDs.roadmapList]: {
    [UserAction.RoadmapListActions.CHANGE_STATUS]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.HorizontalCoordinator],
    [UserAction.RoadmapListActions.CREATE_DOCUMENT]: [AuthRolesEnum.PmoSecretariat],
    [UserAction.RoadmapListActions.CHANGE_CHAPTER]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.HorizontalCoordinator],
    [UserAction.RoadmapListActions.SIGN_CHAPTER]: [AuthRolesEnum.Negotiator],
    [UserAction.RoadmapListActions.CREATE_TASK]: [AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieSecretariat],
    [UserAction.RoadmapListActions.SEND_FOR_SIGNATURE]: [AuthRolesEnum.Negotiator],
    [UserAction.RoadmapListActions.GENERATE_DOCUMENT]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.HorizontalCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.Negotiator],
    [UserAction.RoadmapListActions.VIEW_SIGNED_DOCUMENTS]: [AuthRolesEnum.Negotiator, AuthRolesEnum.PmoSecretariat],
    [UserAction.RoadmapListActions.CHANGE_NEGOTIATOR]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.HorizontalCoordinator],
    [UserAction.RoadmapListActions.CHANGE_CHIEF]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.HorizontalCoordinator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.Negotiator],
    [UserAction.RoadmapListActions.VIEW_COORDINATOR]: [AuthRolesEnum.ChapterCoordinator],
  },
  [FeatureIDs.roadmapTask]: {
    [UserAction.RoadmapTaskActions.CREATE_TASK]: [AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.GnpieMember],
  },
  [FeatureIDs.roadmapSubtask]: {
    [UserAction.RoadmapSubtaskActions.REMOVE_MEMBER]: [AuthRolesEnum.GnpieSecretariat],
  },
  [FeatureIDs.albanianLaws]: {
    [UserAction.AlbanianLawsActions.VIEW_LAWS]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.Negotiator],
    [UserAction.AlbanianLawsActions.ADD_ACT]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.HorizontalCoordinator, AuthRolesEnum.Negotiator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.GnpieMember],
    [UserAction.AlbanianLawsActions.CHANGE_CHAPTER]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.HorizontalCoordinator],
    [UserAction.AlbanianLawsActions.EDIT_ACT]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ClusterCoordinator, AuthRolesEnum.HorizontalCoordinator, AuthRolesEnum.ChiefGnpieSecretariat, AuthRolesEnum.GnpieSecretariat, AuthRolesEnum.Negotiator],
  },
  [FeatureIDs.report]: {
    [UserAction.ReportActions.CHANGE_CHAPTER]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.HorizontalCoordinator, AuthRolesEnum.ClusterCoordinator]
  },
  [FeatureIDs.topicTree]: {
    [UserAction.TopicTreeActions.CHANGE]: [AuthRolesEnum.PmoSecretariat, AuthRolesEnum.MainNegotiator, AuthRolesEnum.ChapterCoordinator, AuthRolesEnum.ClusterCoordinator]
  },
  [FeatureIDs.nenkomitetet]: undefined,
  [FeatureIDs.userManual]: undefined,
  [FeatureIDs.errorLogs]: undefined,
  [FeatureIDs.roadmapPlan]: undefined,
};

export const HORIZONTAL_ROLE_MAPPING: { [key in AuthModules]: Array<FeatureIDs> } = {
  [AuthModules.pkie]: [FeatureIDs.topicTree, FeatureIDs.iaga, FeatureIDs.lga, FeatureIDs.workPlanList, FeatureIDs.workPlanView, FeatureIDs.acquisList, FeatureIDs.acquisView, FeatureIDs.acquisTaskView, FeatureIDs.albanianLaws, FeatureIDs.documentDetail],
  [AuthModules.integrimi]: [FeatureIDs.topicTree, FeatureIDs.documentDetail, FeatureIDs.integration, FeatureIDs.integrationCase, FeatureIDs.integrationTask, FeatureIDs.integrationQuestions, FeatureIDs.roadmapList, FeatureIDs.roadmapTask, FeatureIDs.roadmapSubtask],
  [AuthModules.msa]: [FeatureIDs.topicTree, FeatureIDs.documentDetail, FeatureIDs.progressReportList, FeatureIDs.bankOfQuestions, FeatureIDs.progressReportView, FeatureIDs.progressReportTask, FeatureIDs.recommendationsList, FeatureIDs.bookmarkList, FeatureIDs.bookmarkView, FeatureIDs.bookmarkTaskView, FeatureIDs.nenkomitetet],
  [AuthModules.eLibrary]: [FeatureIDs.topicTree, FeatureIDs.documents, FeatureIDs.documentDetail],
  [AuthModules.users]: [FeatureIDs.topicTree, FeatureIDs.userManagement, FeatureIDs.institutionManagement],
  [AuthModules.calendar]: [FeatureIDs.topicTree, FeatureIDs.calendar, FeatureIDs.calendarTask],
  [AuthModules.reports]: [FeatureIDs.topicTree, FeatureIDs.documentDetail, FeatureIDs.dashboard, FeatureIDs.report, FeatureIDs.chapterPassport],
  [AuthModules.communication]: [FeatureIDs.topicTree, FeatureIDs.documentDetail, FeatureIDs.priorityList, FeatureIDs.trainingNeeds, FeatureIDs.supportFields, FeatureIDs.taiexDb, FeatureIDs.taiexNeeds, FeatureIDs.twinningDb, FeatureIDs.twinningNeeds, FeatureIDs.expertsDb, FeatureIDs.donatorsDb, FeatureIDs.donatorView, FeatureIDs.projectsDb, FeatureIDs.projectView],
}
