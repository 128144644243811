import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MisAuthService } from '@core/services/mis-auth.service';
import { GlobalState } from '@core/interfaces/state';
import { Store } from '@ngrx/store';

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {

  constructor(private auth: MisAuthService, private store: Store<GlobalState>) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if(request.url.includes(environment.hostActionDomain)) {
      let token = this.auth.client.getAccessToken()
      if (token) {
        request = request.clone({
          setHeaders: {
            'Authorization': `Bearer ${token}`,
          },
        })
      } else {
        // Return an Observable that emits an error
        return throwError(() => new Error('Token not found'))
      }
    }
    // if(request.url.includes(environment.approxUrl)) {
    //   const token = selectSyncStore(selectApproxToken, this.store)
    //   if(token) {
    //     request = request.clone({
    //       setHeaders: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     })
    //   } else {
    //     // Cancel request
    //     return throwError(() => new Error('Token not found'))
    //   }
    // }
    return next.handle(request);
  }
}
